import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonJustify, ButtonSize, ButtonType } from './Button';
import { Button as ButtonCn } from './ui/button';

export const ImageUploader: React.FC<{
  imageUrl: string | undefined;
  uploadingImage: boolean;
  onImageSelected: React.ChangeEventHandler;
  onRemoveImageClick: React.MouseEventHandler;
  bottomText?: string;
  disabled?: boolean;
}> = ({
  imageUrl,
  uploadingImage,
  onImageSelected,
  onRemoveImageClick,
  bottomText,
  disabled,
}) => {
  const { t } = useTranslation(['common']);

  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      <div
        className='relative w-5/6 max-w-96 cursor-pointer rounded border-2 border-dotted border-gray-extralight60 px-10 pb-12 pt-14'
        onClick={handleUploadClick}
      >
        <div className='flex flex-col items-center justify-center'>
          <div className='w-64 text-center'>
            {(!imageUrl || uploadingImage) && (
              <>
                <div className='inline-block'>
                  <FontAwesomeIcon
                    size='4x'
                    icon={faUpload as IconProp}
                    className={'mb-2 bg-transparent text-gray-light30'}
                  />
                </div>
                <div className='w-64'>
                  {!uploadingImage && (
                    <p className='text-gray-light50'>
                      {t('upload_image', { ns: 'common' })}
                    </p>
                  )}
                  {uploadingImage && (
                    <Button
                      type={ButtonType.upload}
                      size={ButtonSize.small}
                      justify={ButtonJustify.center}
                      fullWidth={true}
                      loading={true}
                      disabled={true}
                      loadingTxt={t('loading_adding', { ns: 'common' })}
                    >
                      {t('upload_image', { ns: 'common' })}
                    </Button>
                  )}
                  <input
                    ref={hiddenFileInput}
                    className='hidden'
                    type='file'
                    onChange={onImageSelected}
                    accept='image/png, image/jpeg, image/jpg, image/webp'
                    disabled={disabled}
                  />
                </div>
              </>
            )}
            {imageUrl && !uploadingImage && (
              <>
                <div className='flex flex-col items-center'>
                  <img className='mb-3 h-24' src={imageUrl} alt='upload' />
                  <ButtonCn
                    variant='text'
                    type='button'
                    onClick={onRemoveImageClick}
                    className='text-gray-light50'
                  >
                    {t('action_remove', { ns: 'common' })}
                  </ButtonCn>
                </div>
              </>
            )}
          </div>
        </div>
        {bottomText && (
          <div className='mt-2 text-gray-light60'>{bottomText}</div>
        )}
        {disabled && (
          <div className='absolute bottom-0 left-0 right-0 top-0 cursor-not-allowed bg-whiteTransparent-50'></div>
        )}
      </div>
    </>
  );
};
